import { Icons } from 'utils/icons'

import FeatureTopic from './FeatureTopic'
import * as S from './FeatureTopic.styles'

const FeatureTopicList = () => {
  const ticketIcon = <Icons.Ticket color="var(--gray500)" width={24} height={24} />
  const coinIcon = <Icons.Coin color="var(--gray500)" width={24} height={24} />
  const cashbackIcon = <Icons.Cashback color="var(--gray500)" width={24} height={24} />

  const features = [
    {
      id: 1,
      icon: coinIcon,
      description: 'Concorra a 6 sorteios de R$ 7.000,00 durante o ano',
    },
    {
      id: 2,
      icon: ticketIcon,
      description: 'Raspe uma Sortezinha, ache o prêmio e ganhe até R$ 100,00 na hora',
    },
    {
      id: 3,
      icon: cashbackIcon,
      description: 'Resgate R$ 1,50 por cada compra de Sortezinha após a vigência',
    },
  ]

  return (
    <S.FeatureTopicList data-testid="feature-topic-list">
      {features.map((feature) => (
        <FeatureTopic key={feature.id} icon={feature.icon} description={feature.description} />
      ))}
    </S.FeatureTopicList>
  )
}

export default FeatureTopicList
