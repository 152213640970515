import styled from 'styled-components'

export const Slider = styled.div`
  .slider {
    max-width: 430px;

    .slick-slider {
      .slick-list {
        overflow: visible;
        .slick-track {
          display: flex;
          align-items: stretch;
        }
      }

      .slick-slide {
        display: block;
        padding: 0 4px;
        div {
          height: 240px; // make height dynamic
        }
      }

      .slick-dots {
        position: relative;
        bottom: auto;
        margin-top: 16px;

        li {
          transition: all 0.3s linear;
          transform-origin: center;
          button {
            margin-top: 0;
          }
        }
      }
    }
  }
`

export const TestimonialCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  border: 1px solid var(--gray200);
  border-radius: 12px;

  .span-padding {
    padding-bottom: 16px;
  }

  .span-description {
    flex-grow: 4;
    padding-bottom: 16px;
  }
`
