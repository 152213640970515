import styled, { keyframes } from 'styled-components'

const animateLevitation = keyframes`
  0% { 
    transform: translateY(0);
  }
  100% { 
    transform: translateY(3px);
  }
`

export const HeroContainer = styled.div`
  margin: 16px 0 24px 0;
  position: relative;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
`

export const HeroCircle = styled.div<{ $top: number; $left: number; $delay: number }>`
  position: absolute;
  z-index: 5;
  top: ${({ $top }) => $top}%;
  left: ${({ $left }) => $left}%;

  > svg {
    animation: ${animateLevitation} 1s infinite alternate;
    animation-delay: ${({ $delay }) => $delay}s;
  }
`
