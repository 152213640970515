import styled from 'styled-components'

export const Container = styled.div``

export const Section = styled.div`
  height: calc(100vh - 54px - 154px);
  overflow: scroll;
  overflow-x: hidden;
`
export const SectionMargin = styled.div`
  padding: 0 24px;
`

export const Header = styled.div`
  > div {
    margin-bottom: 16px;

    > span {
      display: flex;
      margin: 0;
    }
  }
`

export const HeaderList = styled.ul`
  padding-left: 24px;

  li {
    margin-top: 16px;
  }
`

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 40px 0;
`

export const Testimonials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0;
`

export const Payment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0;
`

export const Footer = styled.div`
  padding: 24px;

  button {
    margin-bottom: 16px;
  }
`

export const Link = styled.span`
  color: var(--primary500);
  font-weight: 700;
`

export const BottomSheetContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  h3 {
    margin: 24px 0 8px;
  }

  button {
    margin-top: 24px;
  }
`
