import { defaultError } from 'common/mocks/ResponseError'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'
import { errorHandling, redirectRoute } from 'store/utils/provider'
import { StateCreator } from 'zustand'
import HomeService from 'service/HomeService'

import { HomeState, IAwards } from './types'

export const createHomeSlice: StateCreator<HomeState, [], [], HomeState> = () => ({
  awards: {
    WINNERS_ALL_TIME: '0',
    WINNER_TODAY: '0',
    ACCUMULATED_PRIZE_VALUE: '0',
  } as unknown as IAwards,
  getHome: async (payload) => {
    useBoundState.getState().setIsLoading(true)

    try {
      const homeResponse = await HomeService.getHome()

      const { eligible, tickets, awarded } = homeResponse
      useBoundState.setState({
        eligibility: { enabled: eligible },
        tickets,
        awards: awarded,
      })

      if (payload) {
        redirectRoute(payload)
      }
    } catch (error) {
      const errorDetails: ErrorDetails = {
        ...defaultError,
        route: TypesRoutes.REQUEST_GO_BACK,
      }

      errorHandling(error as Error, 'HomeService/getHome', errorDetails)
    } finally {
      useBoundState.getState().setIsLoading(false)
    }
  },
})
