import { Text } from '@interco/inter-ui/components/Text'

import * as S from './FeatureTopic.styles'

interface FeatureCardProps {
  icon: JSX.Element
  description: string
}

const FeatureTopic = ({ icon, description }: FeatureCardProps) => (
  <S.FeatureTopic>
    <S.FeatureIcon>{icon}</S.FeatureIcon>
    <Text variant="body-3" weight={400}>
      {description}
    </Text>
  </S.FeatureTopic>
)

export default FeatureTopic
