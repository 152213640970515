import heroPrizeBG from 'assets/png/hero_prize.png'
import heroPrizeDarkBG from 'assets/png/hero_prize_dark.png'

const Preload = () => (
  <>
    <link rel="preload" as="image" href={heroPrizeBG} />
    <link rel="preload" as="image" href={heroPrizeDarkBG} />
  </>
)

export default Preload
