import BaseBridge from 'config/bridge/BaseBridge'
import BaseService from 'config/bridge/BaseService'
import { BridgeMockUserInfo } from 'config/bridge/BridgeMock'
import { IHomeResponse } from 'store/home/types'

export default class HomeService {
  static async getHome() {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': BridgeMockUserInfo.account,
      }
    }

    const response = await BaseService.doExecute<IHomeResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CAPITALIZATION}/home`,
      headers,
    })

    return response.data
  }
}
