export function runCounter(totalSteps: number, isCurrency: boolean) {
  const counterElement = document.querySelector('.poc-counter')
  if (!counterElement) return

  const duration = 4000
  const startTime = performance.now()

  const easeInOutCubic = (progress: number) =>
    progress < 0.5 ? 4 * progress * progress * progress : 1 - (-2 * progress + 2) ** 3 / 2

  const updateCounter = (currentTime: number) => {
    const elapsedTime = currentTime - startTime
    const progress = Math.min(elapsedTime / duration, 1)
    const easedProgress = easeInOutCubic(progress)
    const counter = formatNumber(Math.floor(easedProgress * totalSteps), isCurrency)

    counterElement.textContent = counter.toString()

    if (progress < 1) {
      requestAnimationFrame(updateCounter)
    }
  }

  requestAnimationFrame(updateCounter)
}

function formatNumber(value: number, isCurrency: boolean) {
  return isCurrency
    ? value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : value.toLocaleString('pt-BR')
}
