export enum TagEventsEnum {
  HEADER = 'HEADER',
  CAP_FRONT = 'CAP_FRONT',
  CAPITALIZACAO_ACESSO = 'CAPITALIZACAO_ACESSO',
  CAPITALIZACAO_FLUXO = 'CAPITALIZACAO_FLUXO',
  CAPITALIZACAO_SUCESSO = 'CAPITALIZACAO_SUCESSO',
  FAQ_DUVIDA = 'FAQ_DUVIDA',
  BABI_T_AJUDA = 'Sortezinha',
}

export enum CounterTagTypeEnum {
  WINNERS_ALL_TIME = 'WINNERS_ALL_TIME',
  ACCUMULATED_PRIZE_VALUE = 'ACCUMULATED_PRIZE_VALUE',
}
