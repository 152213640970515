import { Separator } from 'components/Separator/Separator'

import { Text } from '@interco/inter-ui/components/Text'

import { ITestimonial } from './TestimonialsCarousel.types'
import * as S from './TestimonialsCarousel.styles'

interface TestimonialProps {
  testimonial: ITestimonial
}

export const Testimonial = ({ testimonial }: TestimonialProps) => (
  <S.TestimonialCard>
    <Text variant="caption-1" colorWeight={600} bold>
      {testimonial.user}
    </Text>
    <Text variant="caption-1" colorWeight={400} className="span-padding">
      {testimonial.city}
    </Text>
    {testimonial.description && (
      <Text variant="caption-1" colorWeight={400} className="span-description">
        {testimonial.description}
      </Text>
    )}

    <Separator borderWidth="1px" margin="0 0 16px 0" />
    <Text variant="caption-1" colorWeight={400}>
      {testimonial.type}
    </Text>
    <Text variant="body-3" colorWeight={600} bold>
      {testimonial.prize}
    </Text>
  </S.TestimonialCard>
)
