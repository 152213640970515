import styled, { keyframes } from 'styled-components'
import heroPrizeBG from 'assets/png/hero_prize.png'
import heroPrizeBGDark from 'assets/png/hero_prize_dark.png'

const animateGift = keyframes`
  0% { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  100% { transform: scale(1, 1); }
`
export const HeroGift = styled.div`
  position: absolute;
  z-index: 5;
  top: 30px;
  right: 26px;
  width: 24%;
  padding: 16px;

  > svg {
    animation: ${animateGift} 1s infinite alternate;
    animation-delay: 0.5s;
  }
`

export const HeroCounter = styled.div`
  position: absolute;
  display: -webkit-box;
  display: flex;
  width: calc(1.6vw + 10px);
  height: calc(1.6vw + 10px);
  align-items: center;
  top: 26%;
  left: 40%;
  color: var(--darkOrange);
  font-size: 2.4em;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 50%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
`

export const HeroImage = styled.img<{ $isDarkMode: boolean }>`
  content: url(${({ $isDarkMode }) => ($isDarkMode ? heroPrizeBGDark : heroPrizeBG)});
  position: relative;
  max-width: 100%;
  display: block;
  width: 100%;
  min-height: 300px;
`
