import { Carousel } from '@interco/inter-ui/components/Carousel'

import * as S from './TestimonialsCarousel.styles'
import { ITestimonial } from './TestimonialsCarousel.types'
import { Testimonial } from './Testimonial'

interface TestimonialsCarouselProps {
  testimonials: ITestimonial[]
}

export const TestimonialsCarousel = ({ testimonials }: TestimonialsCarouselProps) => {
  const testimonialsArray = testimonials.map((testimonial) => (
    <Testimonial key={testimonial.id} testimonial={testimonial} />
  ))

  return (
    <S.Slider data-testid="testimonials-carousel">
      <Carousel
        dots
        arrows={false}
        infinite={false}
        speed={1000}
        slidesToShow={1}
        slidesToScroll={1}
        dotsType="medium"
        verticalMode={false}
        className="slider"
      >
        {testimonialsArray}
      </Carousel>
    </S.Slider>
  )
}
