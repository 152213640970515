import styled from 'styled-components'

export const FeatureTopicList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`

export const FeatureTopic = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0px;
`

export const FeatureIcon = styled.div`
  margin-right: 16px;
`
