import { TestimonialEnum } from 'common/enums/TestimonialEnum'
import { ITestimonial } from 'components/TestimonialsCarousel/TestimonialsCarousel.types'

export const testimonialsData: ITestimonial[] = [
  {
    id: '1',
    user: 'Rozangella',
    description:
      '"Tô muito feliz com o prêmio porque me ajudou a pagar as contas atrasadas e pretendo abrir meu próprio negócio." ',
    city: 'Blumenau/SC',
    type: TestimonialEnum.SPECIAL,
    prize: 'R$ 7.000,00',
  },
  {
    id: '2',
    user: 'Ryan',
    description:
      '"De primeira, não acreditei! Quando vi os R$ 7 mil na minha conta fiquei muito feliz porque consegui limpar meu nome e comprar uma moto."',
    city: 'Rio Verde/GO',
    type: TestimonialEnum.SPECIAL,
    prize: 'R$ 7.000,00',
  },
  {
    id: '3',
    user: 'Marcos',
    city: 'Teresina/PI',
    description:
      '"Foi uma experiência incrível, com apenas R$ 3 ganhei R$ 100 e recebi o valor instantaneamente na minha conta do Inter! Achei muito legal ser no formato raspadinha digital, pra raspar o bilhete e descobrir meu prêmio!"',
    type: TestimonialEnum.INSTANT,
    prize: 'R$ 100,00',
  },
  {
    id: '4',
    user: 'Lucas',
    city: 'Salto/SP',
    description: '"Achei muito interessante e gostaria de ganhar mais vezes!"',
    type: TestimonialEnum.INSTANT,
    prize: 'R$ 100,00',
  },
  {
    id: '5',
    user: 'Luziene',
    city: 'Maceió/AL',
    description: '"Eu ameeei a experiência de ter ganhado!! 🙏🏾✨❤️"',
    type: TestimonialEnum.INSTANT,
    prize: 'R$ 100,00',
  },
  {
    id: '6',
    user: 'Pedro',
    city: 'Nova Serrana/MG',
    description: '"Gostei bastante! Tive sorte e pretendo comprar mais vezes"',
    type: TestimonialEnum.INSTANT,
    prize: 'R$ 100,00',
  },
  {
    id: '7',
    user: 'Moisés',
    city: 'São Paulo/SP',
    description:
      '"Comprei uma só pra testar e ganhei de primeira! Espero ter mais sorte pra ganhar o grande prêmio de R$ 7 mil"',
    type: TestimonialEnum.INSTANT,
    prize: 'R$ 100,00',
  },
  {
    id: '8',
    user: 'Luana',
    city: 'Senador Firmino/MG',
    description:
      '"Ganhei R$100 na Sortezinha! Eu mal vi o resultado e o dinheiro já tava na minha conta. Nem acreditei! Tava precisando de uma graninha extra 😄🍀"',
    type: TestimonialEnum.INSTANT,
    prize: 'R$ 100,00',
  },
]
